import React from 'react'
import Layout from '../components/Layout'
import { Link, graphql } from 'gatsby'

export default class NotFoundPage extends React.Component {
  render() {
    const { edges: linkArr } = this.props.data.allWpCategory
    const linkBuilder = linkArr.map(({ node: link }) => (
      <Link
        to={`/land/${link.slug}`}
        className="is-block is-size-4 is-size-5-mobile"
      >
        Pretparken in {link.name}
      </Link>
    ))

    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1 className="title">Pagina niet gevonden</h1>
              <h2 className="is-size-4 is-size-5-mobile">
                Deze pagina bestaat niet, probeer een van volgende:
              </h2>
              <div className="div-marg">
                <Link to="/" className="is-block is-size-4 is-size-5-mobile">
                  Naar startpagina
                </Link>
                {linkBuilder}
                <div className="div-marg is-flex is-horizontal-center">
                  <a href="https://ds1.nl/c/?si=7117&li=1373357&wi=332067&ws=">
                    <img src="https://static-dscn.net/7117/1373357/?wi=332067&ws=" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

/* needs correction */
export const notFoundQuery = graphql`
  query nfQuery {
    allWpCategory(filter: { wpParent: { node: { name: { eq: "Land" } } } }) {
      edges {
        node {
          name
          slug
          id
        }
      }
    }
  }
`
